import React from "react"
import PropTypes from "prop-types"

import Footer from "components/Footer/Footer.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
import classNames from "classnames"
import Parallax from "components/Parallax/Parallax.jsx"
import MyHeader from "./MyHeader"

const Layout = props => {
  const { classes } = props
  return (
    <>
      <MyHeader />
      <Parallax small filter image={require("../../src/images/b1.jpg")}>
        <div className={`${classes.container} col-12 text-center`}>
          <h1 className=" text-white title ">{props.title}</h1>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <main>{props.children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withStyles(componentsStyle)(Layout)
