import React from "react"

import SEO from "../components/seo"
import { cardTitle } from "assets/jss/material-kit-react.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import { useStaticQuery } from "gatsby"
import MyImg from "../components/MyImg"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import "../assets/css/card_responsive.css"
import { Tabs } from "antd"
import LayoutTitle from "../components/LayoutTitle"

const styles = {
  cardTitle,
}

const { TabPane } = Tabs

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query MyQueryMenu {
      allMenuCsv {
        edges {
          node {
            descripcion
            img
            nombre
            precio
            tipo
          }
        }
      }
    }
  `)

  const menu = data.allMenuCsv.edges
  const { classes } = props

  return (
    <>
      <SEO title="Menú" />
      <LayoutTitle title="Menú">
        <section className="py-5 rounded" id="menu">
          <div className="container">
            {/* <h1 className={` ${classes.cardTitle} text-center mb-4`}>Menú</h1> */}
            <Tabs
              tabBarStyle={{
                borderBottom: "none",
                // ver como centrar
                // marginLeft: "auto",
                // marginRight: "auto",
                // display: "table",
              }}
              defaultActiveKey="1"
              size="large"
            >
              <TabPane style={{ color: "black" }} tab="Todos" key="1">
                <div className="container">
                  <div className="card-columns">
                    {menu.map(function(e) {
                      return <MyCard data={e} classes={classes} />
                    })}
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Carnes" key="2">
                <div className="container">
                  <div className="card-columns h-100">
                    {menu.map(function(e) {
                      return (
                        <>
                          {/*se muestra con condicion */}
                          {e.node.tipo === "c" && (
                            <MyCard data={e} classes={classes} />
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Guarniciones" key="3">
                <div className="container">
                  <div className="card-columns h-100">
                    {menu.map(function(e) {
                      return (
                        <>
                          {/*se muestra con condicion */}
                          {e.node.tipo === "g" && (
                            <MyCard data={e} classes={classes} />
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Ensaladas" key="4">
                <div className="container">
                  <div className="card-columns h-100">
                    {menu.map(function(e) {
                      return (
                        <>
                          {/*se muestra con condicion */}
                          {e.node.tipo === "e" && (
                            <MyCard data={e} classes={classes} />
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Bebidas" key="5">
                <div className="container">
                  <div className="card-columns h-100">
                    {menu.map(function(e) {
                      return (
                        <>
                          {/*se muestra con condicion */}
                          {e.node.tipo === "b" && (
                            <MyCard data={e} classes={classes} />
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </section>
      </LayoutTitle>
    </>
  )
}

export default withStyles(styles)(IndexPage)

const MyCard = props => {
  return (
    <Card
      //permite que se muestren correctamente las cartas
      style={{ display: "inline-block" }}
      className="bg-primary"
    >
      <CardHeader className="text-center m-0">
        <h5 className={`${props.classes.cardTitle}  text-white`}>
          {props.data.node.nombre}
        </h5>
      </CardHeader>
      <MyImg name={props.data.node.img} />
      {/*se muestra con condicion */}
      {props.data.node.descripcion && (
        <CardBody>
          <p className="card-text  text-white">{props.data.node.descripcion}</p>
        </CardBody>
      )}
      <CardFooter>
        <h2 className={`${props.classes.cardTitle} m-0  text-white`}>
          Q{props.data.node.precio}
        </h2>
      </CardFooter>
    </Card>
  )
}
